import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import MyLink from "../components/Mylink"

const truncateText = (text, wordLimit) => {
  const words = text.split(" ")
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "..."
  }
  return text
}

const projects = ({ data, location }) => {
  const Projects = data.allMarkdownRemark.edges
  return (
    <Layout location={location} title={"Projects"}>
      <SEO title={`Projects`} />
      <div>
        <h1>Projects</h1>

        <Autogrid>
          {Projects &&
            Projects.map(({ node: project }) => {
              const description = project?.frontmatter?.description
              const truncatedDescription = truncateText(description, 20)
              return (
                <ProjectView key={project.frontmatter.title}>
                  <h3 style={{ margin: 0, padding: 0 }}>
                    {project.frontmatter.title}
                  </h3>
                  <p style={{ margin: 5, padding: 0 }}>
                    {project.frontmatter.date}
                  </p>
                  <MyLink
                    Tilte={"Demo Link"}
                    to={project.frontmatter.repository}
                  />

                  <MyLink
                    Tilte={"Preview Project"}
                    to={project.frontmatter.thumbnail}
                  />

                  <p style={{ margin: 0, padding: 0 }}>
                    {truncatedDescription}
                  </p>
                </ProjectView>
              )
            })}
        </Autogrid>
      </div>
    </Layout>
  )
}
export default projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(Projects)/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            repository
            thumbnail
          }
        }
      }
    }
  }
`

const ProjectView = styled.div`
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
`

const Autogrid = styled.div`
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
`
