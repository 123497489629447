import React from "react"
import { useColorMode } from "theme-ui"
const Mylink = ({ to, Tilte }) => {
  const [colorMode] = useColorMode()
  return (
    <a
      target="_blank"
      style={{
        margin: 4,
        fontWeight: "600",
        fontSize: 18,
        boxShadow: `none`,
        color: colorMode === "dark" ? "#37e8ff" : "#E91E63",
      }}
      rel={Tilte}
      href={to}
    >
      {Tilte}
    </a>
  )
}

export default Mylink
